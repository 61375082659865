import s from "./News.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { NewsDisplay } from "../../Components/Main/NewsBlock";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Pagination } from 'antd';

const News = () => {
  const [news, setNews] = useState([]);
  const [load, setLoad] = useState(false);
  const [filter, setFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const { lang } = useSelector((state) => state.lang);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const loadInfo = async () => {
    try {
      const baseUrl = filter === "" 
        ? "https://bricsyoung.com/api/news"
        : `https://bricsyoung.com/api/news?category=${filter}`;
      
      const url = `${baseUrl}${filter === "" ? "?" : "&"}page=${currentPage}`;
      
      const res = await axios.get(url);
      const response = res.data.results
        .map((item) => ({
          date: item.date || "",
          description: item.description || "",
          descriptionen: item.descriptionen || "",
          header: item.header || "",
          headeren: item.headeren || "",
          id: item.id || "",
          category: item.category || "новости",
          img: item.img || "",
        }))
        .filter((el) => {
          let today = new Date();
          let second = new Date(el?.date);
          return second.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0);
        });
      setNews(response);
      setTotal(res.data.count);
      setLoad(true);
    } catch (error) { }
  };

  useEffect(() => {
    loadInfo();
  }, [filter, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <Helmet>
        <title>
          {lang === "ru"
            ? "Новости – BRICS Mediaplatform"
            : "News – BRICS Mediaplatform"}
        </title>
        <meta
          name="description"
          content={
            lang === "ru"
              ? "Узнайте последние новости, связанные с образованием, наукой и молодежным сотрудничеством стран БРИКС."
              : "Stay updated with the latest news on education, science, and youth cooperation in BRICS countries."
          }
        />
        <meta
          name="keywords"
          content={
            lang === "ru"
              ? "новости БРИКС, образование БРИКС, наука БРИКС, молодежь БРИКС, BRICS news, education, science, youth cooperation"
              : "BRICS news, education, science, youth cooperation, новости БРИКС, образование, наука, молодежь"
          }
        />
        <meta name="author" content="BRICS Mediaplatform" />
        <link rel="canonical" href="https://bricsyoung.com/news" />
      </Helmet>
      <div className={s.main}>
        <h1>{lang === "ru" ? "Новости" : "News"}</h1>
        <div className={s.filter}>
          <div className={s.categories}>
            <span
              onClick={() => setFilter("")}
              style={filter === "" ? { color: "#00B4C0" } : { color: "white" }}
              className={s.project}
            >
              {lang === "ru" ? "Все новости" : "All News"}
            </span>
            <span
              onClick={() => setFilter("образование")}
              style={
                filter === "образование"
                  ? { color: "#00B4C0" }
                  : { color: "white" }
              }
              className={s.edu}
            >
              {lang === "ru" ? "Образование" : "Education"}
            </span>
            <span
              onClick={() => setFilter("наука")}
              style={
                filter === "наука" ? { color: "#00B4C0" } : { color: "white" }
              }
              className={s.science}
            >
              {lang === "ru" ? "Наука" : "Science"}
            </span>
            <span
              onClick={() => setFilter("молодёжное")}
              style={
                filter === "молодёжное"
                  ? { color: "#00B4C0" }
                  : { color: "white" }
              }
              className={s.teen}
            >
              {lang === "ru"
                ? "Молодёжное сотрудничество"
                : "Youth cooperation"}
            </span>
          </div>
        </div>
        <div className={s.news}>
          <NewsDisplay response={news} load={load} lang={lang} />
          <div className={s.pagination}>
            <Pagination
              style={{ textAlign: "center", paddingTop: "40px" }}
              align="center"
              current={currentPage}
              total={total}
              onChange={handlePageChange}
              pageSize={20}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
