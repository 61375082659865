import YouTube from "react-youtube";
import s from "../Main/YoutubeLiveStream/Youtube.module.css";
import zagl from "../../assets/zagl.jpg";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { Button, ConfigProvider } from "antd";
import { MainThemes } from "../../themes/MainThemes";

const VideoBlock = ({ title, description, videoId }) => {
  const isSmall = useMediaQuery({ query: "(max-width: 900px)" });
  const { lang } = useSelector((state) => state.lang);
  const opts = {
    height: "460",
    width: "750",
    playerVars: {
      autoplay: 2,
    },
  };
  const smallOpts = {
    height: "400",
    width: "100%",
    playerVars: {
      autoplay: 2,
    },
  };
  const onReady = (event) => {
    event.target.pauseVideo();
  };

  return (
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
        <div style={{backgroundColor: "fef", color: "#415ced"}}>
          <h2>Международный молодежный турник БРИКС+</h2>
          <div className={s.desc}>{description}</div>
        </div>
        
          <div style={{width: "750px", height: "400px"}}>
            <iframe src="https://vkvideo.ru/video_ext.php?oid=598312160&id=456239083&autoplay=1" width="100%" height="100%" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>
          </div>
        
      </div>
  );
};
export default VideoBlock;
const PresentationNew = ({ lang, url }) => {
  return (
    <div className={s.presentationNew}>
      <ConfigProvider theme={MainThemes.gallery}>
        <Button
          style={{ whiteSpace: "normal", height: "auto" }}
          target="_blank"
          href={url}
        >
          {lang === "ru"
            ? "Скачать программу мероприятия"
            : "Download Event Program"}
        </Button>
      </ConfigProvider>
    </div>
  );
};
