import React, { useState } from "react";
import s from "./FlagsBlock.module.css";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import russia from "../../../assets/Main/russia.png";
import brazil from "../../../assets/Main/brazil.png";
import india from "../../../assets/Main/india.png";
import china from "../../../assets/Main/china.png";
import sa from "../../../assets/Main/sa.png";
import egypt from "../../../assets/Main/egypt.png";
import ethi from "../../../assets/Main/ethi.png";
import iran from "../../../assets/Main/iran.png";
import uae from "../../../assets/Main/uae.png";
import saudi from "../../../assets/Main/saudi.png";

const FlagsBlock = () => {
  const { lang } = useSelector((state) => state.lang);
  const [activeCountry, setActiveCountry] = useState(null);

  const countries = [
    {
      id: "russia",
      name: { ru: "Россия", en: "Russia" },
      flag: russia,
      color: "#415CED",
      info: {
        ru: "Российская Федерация - основатель БРИКС, крупнейшая страна мира по площади.",
        en: "Russian Federation - BRICS founder, the largest country in the world by area.",
      },
    },
    {
      id: "brazil",
      name: { ru: "Бразилия", en: "Brazil" },
      flag: brazil,
      color: "#3CB371",
      info: {
        ru: "Федеративная Республика Бразилия - крупнейшая страна Южной Америки.",
        en: "Federative Republic of Brazil - the largest country in South America.",
      },
    },
    {
      id: "india",
      name: { ru: "Индия", en: "India" },
      flag: india,
      color: "#FF9933",
      info: {
        ru: "Республика Индия - вторая по численности населения страна мира.",
        en: "Republic of India - the second most populous country in the world.",
      },
    },
    {
      id: "china",
      name: { ru: "Китай", en: "China" },
      flag: china,
      color: "#DE2910",
      info: {
        ru: "Китайская Народная Республика - самая населенная страна мира.",
        en: "People's Republic of China - the most populous country in the world.",
      },
    },
    {
      id: "south-africa",
      name: { ru: "ЮАР", en: "South Africa" },
      flag: sa,
      color: "#FFB612",
      info: {
        ru: "Южно-Африканская Республика - самая развитая страна Африки.",
        en: "Republic of South Africa - the most developed country in Africa.",
      },
    },
    // БРИКС+
    {
      id: "egypt",
      name: { ru: "Египет", en: "Egypt" },
      flag: egypt,
      color: "#C8102E",
      info: {
        ru: "Арабская Республика Египет - страна с богатой историей и культурой.",
        en: "Arab Republic of Egypt - a country with rich history and culture.",
      },
    },
    {
      id: "ethiopia",
      name: { ru: "Эфиопия", en: "Ethiopia" },
      flag: ethi,
      color: "#006B3F",
      info: {
        ru: "Федеративная Демократическая Республика Эфиопия - древнейшее государство Африки.",
        en: "Federal Democratic Republic of Ethiopia - the oldest state in Africa.",
      },
    },
    {
      id: "iran",
      name: { ru: "Иран", en: "Iran" },
      flag: iran,
      color: "#239F40",
      info: {
        ru: "Исламская Республика Иран - одна из древнейших цивилизаций мира.",
        en: "Islamic Republic of Iran - one of the oldest civilizations in the world.",
      },
    },
    {
      id: "uae",
      name: { ru: "ОАЭ", en: "UAE" },
      flag: uae,
      color: "#00732F",
      info: {
        ru: "Объединенные Арабские Эмираты - федерация семи эмиратов.",
        en: "United Arab Emirates - a federation of seven emirates.",
      },
    },
    {
      id: "saudi-arabia",
      name: { ru: "Саудовская Аравия", en: "Saudi Arabia" },
      flag: saudi,
      color: "#006C35",
      info: {
        ru: "Королевство Саудовская Аравия - крупнейшая страна Аравийского полуострова.",
        en: "Kingdom of Saudi Arabia - the largest country on the Arabian Peninsula.",
      },
    },
  ];

  return (
    <div className={s.flagsBlock}>
      <div className={s.flagsContainer}>
        {countries.map((country) => (
          <motion.div
            key={country.id}
            className={`${s.flagItem} ${
              activeCountry === country.id ? s.active : ""
            }`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() =>
              setActiveCountry(country.id === activeCountry ? null : country.id)
            }
          >
            <div className={s.flagImage}>
              <img
                src={country.flag}
                alt={country.name[lang === "ru" ? "ru" : "en"]}
                style={{ borderColor: country.color }}
              />
            </div>
            <h3>{country.name[lang === "ru" ? "ru" : "en"]}</h3>

            {activeCountry === country.id && (
              <motion.div
                className={s.countryInfo}
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <p>{country.info[lang === "ru" ? "ru" : "en"]}</p>
                <button className={s.learnMoreBtn}>
                  {lang === "ru" ? "Подробнее" : "Learn more"}
                </button>
              </motion.div>
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default FlagsBlock;
