import s from "./Main.module.css";
import NewsCarousel from "../../Components/Main/NewsCarousel";
import EventsBlock from "../../Components/Main/EventsBlock";
import NewsBlock from "../../Components/Main/NewsBlock";
import FlagsBlock from "../../Components/Main/FlagsBlock";
import { useEffect, useState, useRef } from "react";
import PartnersBlock from "../../Components/About/PartnersBlock";
import { useSelector } from "react-redux";
import { MiniHistoryBlock } from "../../Components/About/HistoryBlock";
import { Button } from "antd";
import { NavLink } from "react-router-dom";
import { Modal } from "antd";
import IdeaModal from "../../Components/Footer/IdeaModal";
import { useInView } from "react-intersection-observer";
import VideoBlock from "../../Components/Video/VideoBlock";

const Main = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { lang } = useSelector((state) => state.lang);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Создаем refs для анимации при скролле
  const [infoBlocksRef, infoBlocksInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const [eventsRef, eventsInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [newsRef, newsInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [flagsRef, flagsInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [historyRef, historyInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [partnersRef, partnersInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className={s.main}>
      <NewsCarousel />
      {/* <VideoBlock /> */}
      <div
        ref={infoBlocksRef}
        className={`${s.infoBlocksContainer} ${
          infoBlocksInView ? s.animate : ""
        }`}
      >
        <div className={s.infoBlocks}>
          <div className={s.infoBlock}>
            <div className={s.infoBlockIcon}>
              <i className="fas fa-users"></i>
            </div>
            <h3>{lang === "ru" ? "Присоединяйтесь к нам!" : "Join us!"}</h3>
            <p>
              {lang === "ru"
                ? "Станьте частью нашей команды или предложите свою идею"
                : "Become part of our team or suggest your idea"}
            </p>
            <Button onClick={() => setIsModalOpen(true)}>
              {lang === "ru" ? "Подать заявку" : "Submit application"}
            </Button>
          </div>
          <div className={s.infoBlock}>
            <div className={s.infoBlockIcon}>
              <i className="fas fa-project-diagram"></i>
            </div>
            <h3>{lang === "ru" ? "Наши проекты" : "Our projects"}</h3>
            <p>
              {lang === "ru"
                ? "Узнайте больше о текущих проектах БРИКС"
                : "Learn more about current BRICS projects"}
            </p>
            <NavLink to="/about">
              <Button>{lang === "ru" ? "Подробнее" : "Learn more"}</Button>
            </NavLink>
          </div>
        </div>
      </div>

      <div
        ref={eventsRef}
        className={`${s.sectionContainer} ${eventsInView ? s.animate : ""}`}
      >
        <EventsBlock />
      </div>

      <div
        ref={newsRef}
        className={`${s.sectionContainer} ${newsInView ? s.animate : ""}`}
      >
        <NewsBlock />
      </div>

      <div
        ref={flagsRef}
        className={`${s.sectionContainer} ${flagsInView ? s.animate : ""}`}
      >
        <div className={s.flagsBlockTitle}>
          <h2>{lang === "ru" ? "Страны БРИКС+" : "BRICS+ Countries"}</h2>
          <p>
            {lang === "ru"
              ? "Узнайте больше о странах-участницах БРИКС+"
              : "Learn more about BRICS+ member countries"}
          </p>
        </div>
        <FlagsBlock />
      </div>

      <div
        ref={historyRef}
        className={`${s.sectionContainer} ${historyInView ? s.animate : ""}`}
      >
        <MiniHistoryBlock lang={lang} />
      </div>

      <div
        ref={partnersRef}
        className={`${s.sectionContainer} ${partnersInView ? s.animate : ""}`}
      >
      
        <PartnersBlock lang={lang} />
      </div>

      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={600}
        className={s.ideaModal}
      >
        <IdeaModal
          setIsModalOpen={setIsModalOpen}
          onCancel={() => setIsModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default Main;
