import Footer from "./Components/Footer/Footer";
import {useEffect} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import AboutUs from "./Pages/AboutUs/AboutUs";
import {Provider, useDispatch, useSelector} from "react-redux";
import {store} from "./Redux/store";
import SingleNewsPage from "./Pages/News/SingleNewsPage";
import SingleSportPage from "./Pages/Sports/SingleNewsPage";
import SingleAnoncePage from "./Pages/Announces/SingleNewsPage/index";
import {LOGIN_SUCCESS} from "./Redux/types";
import {setUserData} from "./Redux/user/userActions";
import Forum from "./Pages/Forum";
import Profile from "./Pages/Profile";
import Main from "./Pages/Main";
import Header from "./Components/Header";
import News from "./Pages/News";
import AnnouncementsPage from "./Pages/Announces/index";
import SportsPage from "./Pages/Sports";
import NewPassword from "./Pages/ResetPasswordPages/NewPassword";
import SingleBannerPage from "./Pages/SingleBannerPage";
import SingleCountryPage from "./Pages/SingleCountryPage";
import Activation from "./Pages/Registration/Activation";
import ProfileEdit from "./Pages/ProfileEdit";
import Gallery from "./Pages/Gallery";
import GalleryDatePage from "./Pages/GalleryDatePage";
import VideoGallery from "./Pages/VideoGallery";
import AllMedia from "./Pages/Gallery/AllMedia";
import KolbaForm from "./Pages/Forms/KolbaForm";
import EduHistoryForm from "./Pages/Forms/EduHistoryForm";
import Science from "./Pages/Science";
import ScienceTable from "./Pages/Science/Categories/ScienceTable";
import ScienceCompetitions from "./Pages/Science/Categories/Competitions/ScienceCompetitions";
import ScienceSuccessStories from "./Pages/Science/Categories/ScienceSuccessStories";
import GrantsSinglePage from "./Pages/Science/GrantsSinglePage";
import UniversitySinglePage from "./Pages/Science/UniversitySinglePage";
import AnotherNewsPage from "./Pages/AnotherNewsPage";
import ScienceBusiness from "./Pages/Science/Categories/ScienceBusiness";
import BusinessSinglePage from "./Pages/Science/BusinessSinglePage";
import SuccessStorySinglePage from "./Pages/Science/Categories/SuccessStorySinglePage";
function App() {
    const dispatch = useDispatch();
    const {isAuthenticated} = useSelector((state) => state.auth);
    useEffect(() => {
        const fetchData = async () => {
            if (
                localStorage.getItem("access") &&
                localStorage.getItem("access") !== "undefined"
            ) {
                dispatch({type: LOGIN_SUCCESS});
                let config = {
                    method: "GET",
                    maxBodyLength: Infinity,
                    url: "https://bricsyoung.com/api/auth/users/me/",
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("access")}`,
                    },
                };
                dispatch(setUserData(config));
            }
        };
        fetchData();
    }, [isAuthenticated]);

    const {lang} = useSelector((state) => state.lang);
    return (
        <div>
            <BrowserRouter>
                <Header/>
                <div style={{minHeight: "calc(100vh - 778px)"}}>
                    <Routes>
                        <Route element={<Main lang={lang}/>} path="/"/>
                        <Route element={<Profile/>} path="/cabinet"/>
                        <Route element={<ProfileEdit/>} path="/edit"/>
                        <Route element={<AboutUs lang={lang}/>} path="/about"/>
                        <Route element={<Forum/>} path="/chatroom"/>
                        <Route element={<SingleNewsPage/>} path="/news/:id"/>
                        <Route element={<SingleSportPage/>} path="/sport/:id"/>
                        <Route element={<SingleAnoncePage/>} path="/announcements/:id"/>
                        <Route element={<SingleBannerPage/>} path="/banner/:id"/>
                        <Route element={<SingleCountryPage/>} path="/country/:id"/>
                        <Route element={<GrantsSinglePage/>} path="/contest/:id"/>
                        <Route element={<BusinessSinglePage/>} path="/vacancy/:id"/>
                        <Route element={<UniversitySinglePage/>} path="/science/map/:id"/>
                        <Route element={<News/>} path="/news"/>
                        <Route element={<Gallery/>} path="/gallery/photo"/>
                        <Route element={<AllMedia/>} path="/gallery"/>
                        <Route element={<VideoGallery/>} path="/gallery/video"/>
                        <Route
                            element={
                                <GalleryDatePage
                                    folder="0721"
                                    number="21"
                                    amount={45}
                                    loadCount={5}
                                    date="2024-07-21"
                                />
                            }
                            path="/gallery/photo/2024-07-21"
                        />
                        <Route
                            element={
                                <GalleryDatePage
                                    folder="0722"
                                    number="22"
                                    amount={290}
                                    loadCount={10}
                                    date="2024-07-22"
                                />
                            }
                            path="/gallery/photo/2024-07-22"
                        />
                        <Route
                            element={
                                <GalleryDatePage
                                    folder="0723"
                                    number="23"
                                    amount={791}
                                    loadCount={7}
                                    date="2024-07-23"
                                />
                            }
                            path="/gallery/photo/2024-07-23"
                        />
                        <Route
                            element={
                                <GalleryDatePage
                                    folder="0724"
                                    number="24"
                                    amount={490}
                                    loadCount={10}
                                    date="2024-07-24"
                                />
                            }
                            path="/gallery/photo/2024-07-24"
                        />
                        <Route
                            element={
                                <GalleryDatePage
                                    folder="0725"
                                    number="25"
                                    amount={800}
                                    loadCount={20}
                                    date="2024-07-25"
                                />
                            }
                            path="/gallery/photo/2024-07-25"
                        />
                        <Route
                            element={
                                <GalleryDatePage
                                    folder="0821"
                                    number="1"
                                    amount={100}
                                    loadCount={5}
                                    date="2024-08-21"
                                />
                            }
                            path="/gallery/photo/2024-08-21"
                        />
                        <Route
                            element={
                                <GalleryDatePage
                                    folder="0822"
                                    number="1"
                                    amount={348}
                                    loadCount={6}
                                    date="2024-08-22"
                                />
                            }
                            path="/gallery/photo/2024-08-22"
                        />
                        <Route
                            element={
                                <GalleryDatePage
                                    folder="0823"
                                    number="1"
                                    amount={280}
                                    loadCount={5}
                                    date="2024-08-23"
                                />
                            }
                            path="/gallery/photo/2024-08-23"
                        />
                        <Route element={<AnnouncementsPage/>} path="/announcements"/>
                        <Route element={<SportsPage/>} path="/sport"/>
                        <Route
                            element={<NewPassword/>}
                            path="/password/reset/confirm/:uid/:token"
                        />
                        <Route element={<Activation/>} path="/activate/:uid/:token"/>

                        <Route element={<KolbaForm/>} path="/kolba_registration"/>
                        <Route element={<EduHistoryForm/>} path="/story_registration"/>
                        <Route element={<Science/>} path="/science"/>
                        <Route element={<ScienceTable/>} path="/science/map"/>
                        <Route element={<AnotherNewsPage/>} path="/exp"/>
                        <Route
                            element={<ScienceCompetitions/>}
                            path="/science/competitions/:country"
                        />
                        <Route
                            element={<ScienceSuccessStories/>}
                            path="/science/success_stories"
                        />
                        <Route
                            element={<ScienceBusiness/>}
                            path="/science/business"
                        />
                        <Route
                            element={<SuccessStorySinglePage/>}
                            path="/science/success_stories/:id"
                        />
                    </Routes>
                </div>
                <Footer/>
            </BrowserRouter>
        </div>
    );
}

const BricsApp = () => {
    return (
        <Provider store={store}>
            <App/>
        </Provider>
    );
};
export default BricsApp;
