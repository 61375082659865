import s from "./News.module.css"
import { useEffect, useState } from "react";
import axios from "axios";
import {NewsDisplay} from "../../Components/Main/NewsBlock";
import {useSelector} from "react-redux";
import { Helmet } from "react-helmet";

const SportsPage = () => {
    const [news, setNews] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [load, setLoad] = useState(false);
    const [filter, setFilter] = useState('');
    const {lang} = useSelector(state => state.lang);

    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const loadInfo = async () => {
        
            axios.get("https://bricsyoung.com/api/news?category=спорт").then(res => {
               const data =  res.data.results
               // debugger
               const response = data.map(item => ({
                date: item.date || '',
                description: item.description || '',
                descriptionen: item.descriptionen || '',
                header: item.header || '',
                headeren: item.headeren || '',
                id: item.id || '',
                img: item.img || ''
            }));
            setNews(response);
            setLoad(true);
            })
            
            
       
    }

    useEffect(() => {
        loadInfo();
    }, [filter]);

    useEffect(() => {
        const results = news.filter(p =>
            p.header.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchResults(results);
    }, [searchTerm, news]);

    return (
        <div>
            <Helmet>
                <title>{lang === "ru" ? "Спорт — BRICS+" : "Sport — BRICS+"}</title>
                <meta
                    name="description"
                    content={lang === "ru"
                        ? "Последние новости спорта в рамках платформы BRICS+. Узнайте о событиях, турнирах и достижениях."
                        : "Latest sports news on the BRICS+ platform. Learn about events, tournaments, and achievements."
                    }
                />
                <meta
                    name="keywords"
                    content={lang === "ru"
                        ? "спорт, новости спорта, BRICS, соревнования, достижения"
                        : "sports, sports news, BRICS, tournaments, achievements"
                    }
                />
            </Helmet>
            <div className={s.main}>
                <h1>{lang === "ru" ? 'Спорт' : 'Sport'}</h1>
                <div className={s.filter}>
                    
                    <div className={s.search}>
                        <input id="searchInput" value={searchTerm} onChange={handleChange} type="text"
                               placeholder={lang === "ru" ? 'Поиск...' : 'Search...'}/>
                    </div>
                </div>
                <div className={s.news}>
                    {
                        searchTerm ?
                            <NewsDisplay response={searchResults} load={load} url="/sport/"/>
                            :
                            <NewsDisplay response={news} load={load} url="/sport/"/>
                    }
                </div>
            </div>
        </div>
    )
}

export default SportsPage;

