import s from "./NewsLayout.module.css";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";
import { useSelector } from "react-redux";
import { formatDate, NewsDisplay } from "../../../Components/Main/NewsBlock";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import {
  CalendarOutlined,
  EnvironmentOutlined,
  TeamOutlined,
  FormOutlined,
} from "@ant-design/icons";

const SingleAnoncePage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [news, setNews] = useState({
    start_time: "2000-01-01",
    date: "2000-01-01",
    header: "loading...",
    img: "",
    body: "loading...",
    bodyen: "loading...",
    headeren: "loading...",
    end_time: "2000-01-01",
  });
  const [newsArray, setNewsArray] = useState(null);
  const [response, setResponse] = useState(null);
  const { lang } = useSelector((state) => state.lang);
  const load = true;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    const loadInfo = async () => {
      try {
        const res = await axios.get("https://bricsyoung.com/api/news");
        setNewsArray(res.data.results);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    };
    loadInfo();
  }, []);

  useEffect(() => {
    if (newsArray) {
      const data = Object.values(newsArray)
        .filter(item => item) // Filter out null/undefined items
        .map((item) => ({
          body: item?.body || "",
          bodyen: item?.bodyen || "",
          start_time: item?.start_time || "2001-01-01",
          date: item?.date || "2001-01-01",
          end_time: item?.end_time || "2001-01-01",
          description: item?.description || "",
          descriptionen: item?.descriptionen || "",
          header: item?.header || "",
          headeren: item?.headeren || "",
          id: item?.id || "",
          img: item?.img ? item.img : "",
        }));
      setResponse(data);
    }
  }, [newsArray]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://bricsyoung.com/api/science?id=${id}`
        );
        const data = await response.json();
        setNews(data[0]);
      } catch (e) {
      } finally {
        setLoading2(false);
      }
    };
    fetchData();
  }, [id]);

  const renderEventDetails = () => (
    <div className={s.eventDetails}>
      <div className={s.eventInfo}>
        <div className={s.infoItem}>
          <CalendarOutlined className={s.infoIcon} />
          <span>{formatDate(news.start_time, lang)}</span>
        </div>
        <div className={s.separator}>•</div>
        <div className={s.infoItem}>
          <EnvironmentOutlined className={s.infoIcon} />
          <span>{lang === "ru" ? news.place : news.placeen}</span>
        </div>
        <div className={s.separator}>•</div>
        <div className={s.infoItem}>
          <TeamOutlined className={s.infoIcon} />
          <span>{lang === "ru" ? news.description : news.descriptionen}</span>
        </div>
      </div>
    </div>
  );

  if (loading && loading2) {
    return <div>Loading...</div>;
  }
  if (!response) {
    return <div>No response...</div>;
  }

  return (
    <div className={s.root}>
      <div className={s.layout}>
        <div className={s.main}>
          <article className={s.articleDetailBlock}>
            <div className={s.title}>
              <div className={s.date}>{formatDate(news.start_time, lang)}</div>
              <h1>{lang === "ru" ? news.header : news.headeren}</h1>
            </div>

            <img src={"https://bricsyoung.com" + news.img} alt="" />

            {renderEventDetails()}

            <div className={s.text}>
              {lang === "ru"
                ? parse(news?.body, {
                    replace: (domNode) => {
                      if (domNode.name === "a") {
                        return (
                          <a
                            href={domNode.attribs.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={s.link}
                          >
                            {domNode.children[0].data}
                          </a>
                        );
                      }
                    },
                  })
                : parse(news?.bodyen)}
            </div>

            {news.category === "анонсы" && (
              <div className={s.registerBlock}>
                <Button
                  type="primary"
                  size="large"
                  icon={<FormOutlined />}
                  onClick={() =>
                    window.open(
                      "https:/docs.google.com/forms/d/e/1FAIpQLScziQTJ9TRWH0ExtHb31uiiZ-HtAegexuwVMfdbvrZoxR5srQ/viewform?usp=preview",
                      "_blank"
                    )
                  }
                >
                  {lang === "ru" ? "Зарегистрироваться" : "Register"}
                </Button>
              </div>
            )}
          </article>

          <BlockTitleWithUrl
            size="27px"
            ruTitle="Также рекомендуем"
            enTitle="Also Read"
            url="/news"
          />
          <NewsDisplay response={response.slice(0, 3)} load={load} />
        </div>
      </div>
    </div>
  );
};

export const BlockTitleWithUrl = ({ url, ruTitle, enTitle, size }) => {
  const { lang } = useSelector((state) => state.lang);
  return (
    <div className={s.bottom}>
      <div className={s.also}>
        <h2 style={{ fontSize: size }}>{lang === "ru" ? ruTitle : enTitle}</h2>
        <NavLink to={url}>
          <span>{lang === "ru" ? "Смотреть все" : "See All"}</span>
          <span className={s.arrow}>
            <ArrowRightOutlined />
          </span>
        </NavLink>
      </div>
    </div>
  );
};

export default SingleAnoncePage;
