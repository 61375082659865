import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from "antd";
import {useSelector} from "react-redux";
import {ScienceLayout} from "../../../Layouts/Science/ScienceLayout";
import s from "../Science.module.css"
import axios from "axios";
import { Link } from "react-router-dom";

const ScienceSuccessStories = () => {
    const {lang} = useSelector(state => state.lang);
    const [news, setNews] = useState([]);
    const [load, setLoad] = useState(false);


    const loadInfo = async () => {
        try {
            const res = await axios.get("https://bricsyoung.com/api/history_of_education");
            setNews(res.data);
            setLoad(true);
        } catch (error) {
        }
    };

    useEffect(() => {
        loadInfo();
    }, []);

    const response = Object.values(news)
        .map(function (item) {
            return {
                description: item.description,
                descriptionen: item.descriptionen,
                name: item.name,
                nameen: item.nameen,
                id: item.id,
                program: item.program,
                programen: item.programen,
                university_ref: item.university_ref,
                img: "https://bricsyoung.com" + item.img,
            };
        })

    return (
        <ScienceLayout title={lang === 'ru' ? 'Истории успеха' : 'Success Stories'} lang={lang}>
            <Row gutter={[32, 32]}>
                {load ? response.map((el) => (
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} key={el.id}>
                            <AuthorCard {...el} lang={lang}/>
                        </Col>)) :

                    Array.from({length: 8}).map((_, index) => (
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} key={index}>
                            <Card loading={true}/>
                        </Col>))

                }
            </Row>
        </ScienceLayout>
    )
};

const AuthorCard = ({description, descriptionen, img, name, nameen, university_ref = "", lang, id}) => {
    return (
        <div className={s.author}>
            <img src={img} alt={descriptionen}/>
            <div className={s.info}>
                <Link to={`/science/success_stories/${id}`} className={s.name}>
                    {lang === 'ru' ? name : nameen}
                </Link>
                <Link to={`/science/success_stories/${id}`} className={s.description}>
                    {lang === 'ru' ? description : descriptionen}
                </Link>
                <div style={{ minHeight: "1.25em", lineHeight: "1.25"}}>
                    {(university_ref !== "" && university_ref !== null) ? <a href={university_ref}>{lang === 'ru' ? "Университет" : "University"}</a> : <></>}
                </div>
            </div>

        </div>
    )
}

export default ScienceSuccessStories;
