import React, { useState, useEffect } from "react";
import s from "./Partners.module.css";
import p1 from "../../../assets/Partners/SN.jpg";
import p2 from "../../../assets/Partners/svg/sabya.svg";
import p3 from "../../../assets/Partners/svg/ТВ БРИКС.svg";
import p4 from "../../../assets/Partners/svg/assamb_2.png";
import p5 from "../../../assets/Partners/svg/agency.svg";
import p6 from "../../../assets/Partners/svg/iyesf.svg";
import p7 from "../../../assets/Partners/svg/rudn.svg";
import p8 from "../../../assets/Partners/svg/aho.svg";
import p9 from "../../../assets/Partners/svg/ra.svg";
import p10 from "../../../assets/Partners/svg/bayeux.svg";
import p11 from "../../../assets/Partners/svg/youth.svg";
import p12 from "../../../assets/Partners/svg/smart.png";
import p13 from "../../../assets/Partners/svg/hpgu.svg";
import p14 from "../../../assets/Partners/svg/red.svg";
import p15 from "../../../assets/Partners/svg/skur.svg";
import p16 from "../../../assets/Partners/svg/inostr.png";
import { Tooltip, Button } from "antd";
import { useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const PartnersBlock = () => {
  const { lang } = useSelector((state) => state.lang);
  const [activePartner, setActivePartner] = useState(null);
  const [showAllPartners, setShowAllPartners] = useState(false);

  // Автоматическое переключение подсветки партнеров
  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * partnersArray.length);
      setActivePartner(partnersArray[randomIndex].key);

      // Сбросить активного партнера через 2 секунды
      setTimeout(() => {
        setActivePartner(null);
      }, 2000);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={s.partnersContainer}>
      

      <div className={s.partnersTitle}>
        <h2>{lang === "ru" ? "Наши партнеры" : "Our Partners"}</h2>
        <p>
          {lang === "ru"
            ? "Организации, с которыми мы сотрудничаем"
            : "Organizations we collaborate with"}
        </p>
      </div>

      {/* Бегущая строка всегда видна */}
      <div className={s.partnersMarquee}>
        <div className={s.marqueeTrack}>
          {[...partnersArray, ...partnersArray].map((partner, index) => (
            <div
              key={`${partner.key}-${index}`}
              className={`${s.marqueeItem} ${
                activePartner === partner.key ? s.active : ""
              }`}
            >
              <img
                src={partner.img}
                alt={lang === "ru" ? partner.title : partner.enTitle}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Кнопка для разворачивания/сворачивания сетки партнеров */}
      <div className={s.viewAllContainer}>
        <Button
          type="primary"
          size="large"
          className={s.viewAllButton}
          onClick={() => setShowAllPartners(!showAllPartners)}
        >
          {showAllPartners
            ? lang === "ru"
              ? "Свернуть"
              : "Collapse"
            : lang === "ru"
            ? "Показать всех партнеров"
            : "Show all partners"}
          {showAllPartners ? <UpOutlined /> : <DownOutlined />}
        </Button>
      </div>

      {/* Анимированное появление/исчезновение сетки партнеров */}
      <AnimatePresence>
        {showAllPartners && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.5 }}
            className={s.partnersGridContainer}
          >
            <div className={s.partnersGrid}>
              {partnersArray.map((partner) => (
                <motion.div
                  key={partner.key}
                  className={`${s.partnerCard} ${
                    activePartner === partner.key ? s.active : ""
                  }`}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0 10px 25px rgba(0, 0, 0, 0.1)",
                  }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.5,
                    delay: parseInt(partner.key.replace("p", "")) * 0.05,
                  }}
                >
                  <Tooltip
                    title={lang === "ru" ? partner.title : partner.enTitle}
                  >
                    <a
                      target="_blank"
                      href={partner.url}
                      rel="noopener noreferrer"
                    >
                      <div className={s.partnerImageContainer}>
                        <img
                          src={partner.img}
                          alt={lang === "ru" ? partner.title : partner.enTitle}
                        />
                      </div>
                      <div className={s.partnerName}>
                        {lang === "ru"
                          ? partner.title.length > 30
                            ? partner.title.substring(0, 30) + "..."
                            : partner.title
                          : partner.enTitle.length > 30
                          ? partner.enTitle.substring(0, 30) + "..."
                          : partner.enTitle}
                      </div>
                    </a>
                  </Tooltip>
                </motion.div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PartnersBlock;

const partnersArray = [
  {
    key: "p1",
    img: p1,
    title: "ООО «Cпортивное новаторство»",
    enTitle: "Sport Novators",
    url: "https://sportnovators.ru/",
  },
  {
    key: "p2",
    img: p2,
    title: "Южно-Африканская Молодежная Ассоциация БРИКС (SABYA, ЮАР)",
    enTitle: "SABYA (South African BRICS Youth Association)",
    url: "https://sabya.co.za/",
  },
  {
    key: "p3",
    img: p3,
    title: "ТВ БРИКС",
    enTitle: "TV BRICS",
    url: "https://tvbrics.com/en/",
  },
  {
    key: "p4",
    img: p4,
    title: "Ассамблея народов Евразии и Африки",
    enTitle: "Eurasian And African Peoples' Assembly",
    url: "http://eurasia-assembly.org/",
  },
  {
    key: "p5",
    img: p5,
    title: "Агентство культурной и научной дипломатии (г. Екатеринбург)",
    enTitle: "Agency of Cultural and Science Diplomacy",
    url: "https://cdaekb.ru/",
  },
  {
    key: "p6",
    img: p6,
    title:
      "Международный фонд повышения образования и навыков среди молодежи (IYESF, Индия)",
    enTitle: "IYESF (International Youth Edu-Skills Foundation)",
    url: "https://www.iyesf.com/",
  },
  {
    key: "p7",
    img: p7,
    title: "Российский университет дружбы народов имени Патриса Лумумбы",
    enTitle: "RUDN University",
    url: "https://eng.rudn.ru/",
  },
  {
    key: "p8",
    img: p8,
    title: "АНО «Содружество спортивной дипломатии»",
    enTitle: "The Commonwealth of Sports Diplomacy",
    url: "https://vk.com/sportdilomacy",
  },
  {
    key: "p9",
    img: p9,
    title:
      "Региональное отделение Русско-Азиатского Союза промышленников и предпринимателей (РАСПП, г. Ульяновск)",
    enTitle: "The Russian-Asian Union of Industrialists and Entrepreneurs",
    url: "https://raspp.ru/",
  },
  {
    key: "p10",
    img: p10,
    title: "ABFB Aliança Bayeux Franco Brasileira (Бразилия)",
    enTitle: "ABFB (Aliança Bayeux Franco Brasielira)",
    url: "https://aliancabayeux.org.br/",
  },
  {
    key: "p11",
    img: p11,
    title: "Клуб молодежи мира (г. Казань)",
    enTitle: "Youth of the World Club",
    url: "https://clubmolodyozhmira.tilda.ws/",
  },
  {
    key: "p12",
    img: p12,
    title: "Smart Mir Services",
    enTitle: "Smart Mir Services",
    url: "https://www.smartmirservices.com/",
  },
  {
    key: "p13",
    img: p13,
    title: "ФГБОУ ВО «Херсонский государственный педагогический университет» ",
    enTitle: "Kherson State Pedagogical University",
    url: "https://hgpurf.ru/",
  },
  {
    key: "p14",
    img: p14,
    title: "Комитет здравоохранения БРИКС",
    enTitle:
      "BRICS Committee for International Cooperation in Health and Healthcare",
    url: "https://bricshealthcare.com/",
  },
  {
    key: "p15",
    img: p15,
    title:
      "Ассоциация молодежных обменов Китая и России при Союзе китайских учащихся в России ",
    enTitle:
      "The Association of Youth Exchanges of China and Russia under the Union of Chinese Students in Russia",
    url: "https://vk.com/club163002051",
  },
  {
    key: "p16",
    img: p16,
    title: "Приключения иностранцев в России",
    enTitle: "Adventures of foreigners in Russia",
    url: "https://t.me/inostranets_v_RF",
  },
];
