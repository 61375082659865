import s from "./News.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import {EventsDisplay} from "../../Components/Main/EventsBlock";
import {useSelector} from "react-redux";

const AnnouncementsPage = () => {
    const [news, setNews] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [load, setLoad] = useState(false);
    const [filter, setFilter] = useState('');
    const {lang} = useSelector(state => state.lang);

    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        axios.get("https://bricsyoung.com/api/science").then(res => {
            setNews(res.data);
            setLoad(true);
        })

    }, [filter]);

    useEffect(() => {
        const results = news.filter(p =>
            p.header.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchResults(results);
    }, [searchTerm, news]);

    return (
        <div>
            <div className={s.main}>
                <h1>{lang === "ru" ? 'Анонсы' : 'Announcements'}</h1>
                <div className={s.filter}>
                    <div className={s.search}>
                        <input id="searchInput" value={searchTerm} onChange={handleChange} type="text"
                               placeholder={lang === "ru" ? 'Поиск...' : 'Search...'}/>
                    </div>
                </div>
                <div className={s.news}>
                    {
                        searchTerm ?
                            <EventsDisplay response={searchResults} load={load} lang={lang}/>
                            :
                            <EventsDisplay response={news} load={load} url={"/announcements/"} lang={lang}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default AnnouncementsPage;

