import s from "../../../Pages/News/SingleNewsPage/NewsLayout.module.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";
import { useSelector } from "react-redux";
import { Carousel } from "antd";
import { NewCustomPrevArrow, NewCustomNextArrow } from "../../../Components/Main/NewsCarousel";
import {useMediaQuery} from "react-responsive";

const SuccessStorySinglePage = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [newsArray, setNewsArray] = useState({
        description: "",
        descriptionen: "",
        name: "",
        nameen: "",
        id: "",
        program: "",
        programen: "",
        university_ref: "",
        img: "",
        images: [],
    });
    const { lang } = useSelector((state) => state.lang);
    const isSmall = useMediaQuery({query: '(max-width: 768px)'})
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);

    useEffect(() => {
        const loadInfo = async () => {
            try {
                const res = await axios.get("https://bricsyoung.com/api/history_of_education?id=" + id);
                setNewsArray(res.data[0]);
            } catch (e) {
            } finally {
                setLoading(false);
            }
        };
        loadInfo();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className={s.root}>
            <div className={s.layout}>
                <div className={s.main}>
                    <article className={s.articleDetailBlock}>
                        <div className={s.title}>
                            <h1>{lang === "ru" ? newsArray?.name : newsArray?.nameen}</h1>
                        </div>
                        <div className={s.imageContainer}>
                            <Carousel 
                                autoplay
                                arrows={true}
                                prevArrow={<NewCustomPrevArrow />}
                                nextArrow={<NewCustomNextArrow />}
                            >
                                {newsArray?.images?.map((image, index) => (
                                    <div key={index} className={s.imageWrapper}>
                                        <img
                                            src={"https://bricsyoung.com" + image.image}
                                            alt=""
                                            className={s.sliderImage}
                                        />
                                    </div>
                                ))}
                            </Carousel>
                        </div>

                        <div className={s.text}>
                            <b>{lang === "ru" ? newsArray?.description : newsArray?.descriptionen}</b>
                        </div>
                        <div className={s.text}>
                            {lang === "ru" ? parse(newsArray?.program) : parse(newsArray?.programen)}
                        </div>
                    </article>
                </div>
            </div>
        </div>
    );
};

export default SuccessStorySinglePage;
