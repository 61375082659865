import React, { useState, useEffect } from "react";
import s from "./News.module.css";
import { Card, Col, Row, Tooltip, Button } from "antd";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { BlockTitleWithUrl } from "../../../Pages/News/SingleNewsPage";
import { motion } from "framer-motion";
import { ArrowRightOutlined, CalendarOutlined } from "@ant-design/icons";
import { format } from "date-fns";
import { enUS, ru } from "date-fns/locale";

const NewsBlock = () => {
  const [respData, setData] = useState("");
  const [load, setLoad] = useState(false);
  const { lang } = useSelector((state) => state.lang);
  const isSmall = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    axios.get("https://bricsyoung.com/api/news").then((res) => {
      setData(res.data.results);
      setLoad(true);
    });
  }, []);

  const response = Object.values(respData)
    .map(function (item) {
      return {
        body: item.body,
        bodyen: item.bodyen,
        date: item.date,
        description: item.description,
        descriptionen: item.descriptionen,
        header: item.header,
        headeren: item.headeren,
        id: item.id,
        img: item.img,
      };
    })
    .slice(0, 3); // Ограничиваем до 3 новостей

  return (
    <div className={s.news}>
      <div className={s.info}>
        <BlockTitleWithUrl
          size={isSmall ? "32px" : "60px"}
          ruTitle="Новости"
          enTitle="News"
          url="/news"
        />
        <div className={s.newsDescription}>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            {lang === "ru"
              ? "Актуальные новости о событиях и проектах БРИКС+"
              : "Latest news about BRICS+ events and projects"}
          </motion.p>
        </div>
        <NewsDisplay response={response} load={load} lang={lang} />
        <div className={s.viewAllContainer}>
          <NavLink to="/news">
            <Button
              type="primary"
              size="large"
              className={s.viewAllButton}
              icon={<ArrowRightOutlined />}
            >
              {lang === "ru" ? "Смотреть все новости" : "View all news"}
            </Button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export const NewsDisplay = ({ response, load = true, lang }) => {
  return (
    <Row gutter={[32, 32]}>
      {load
        ? response.map((el, index) => (
            <Col xs={24} sm={24} md={8} lg={8} xl={8} key={el.id}>
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 + 0.3 }}
              >
                <NewsCard {...el} url={`/news/${el.id}`} lang={lang} />
              </motion.div>
            </Col>
          ))
        : Array.from({ length: 3 }).map((_, index) => (
            <Col xs={24} sm={24} md={8} lg={8} xl={8} key={index}>
              <Card loading={true} className={s.loadingCard} />
            </Col>
          ))}
    </Row>
  );
};

export const formatNewsDate = (dateString, lang) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return format(date, "dd MMMM yyyy", { locale: lang === "ru" ? ru : enUS });
};

// Экспортируем функцию под старым именем для обратной совместимости
export const formatDate = formatNewsDate;

export const NewsCard = ({
  header,
  headeren,
  img,
  url,
  date,
  description,
  descriptionen,
  lang,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <NavLink to={url}>
      <motion.div
        className={s.newsCard}
        whileHover={{ y: -8 }}
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
      >
        <div className={s.imageContainer}>
          <img
            src={img}
            alt={lang === "ru" ? header : headeren}
            className={s.newsImage}
          />
          <motion.div
            className={s.overlay}
            initial={{ opacity: 0 }}
            animate={{ opacity: isHovered ? 0.6 : 0 }}
            transition={{ duration: 0.3 }}
          />
        </div>
        <div className={s.cardContent}>
          <div className={s.dateContainer}>
            <CalendarOutlined className={s.calendarIcon} />
            <span>{formatNewsDate(date, lang)}</span>
          </div>
          <h3 className={s.newsTitle}>{lang === "ru" ? header : headeren}</h3>
          <p className={s.newsDescription}>
            {lang === "ru" ? description : descriptionen}
          </p>
          <motion.div
            className={s.readMore}
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: isHovered ? 1 : 0, x: isHovered ? 0 : -10 }}
            transition={{ duration: 0.3 }}
          >
            {lang === "ru" ? "Читать далее" : "Read more"}{" "}
            <ArrowRightOutlined className={s.readMoreIcon} />
          </motion.div>
        </div>
      </motion.div>
    </NavLink>
  );
};

export default NewsBlock;
